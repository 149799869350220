import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Overview from "../Overview/Overview";
import Sidebar from "../Sidebar/Sidebar";
import "./sidebarWithOverview.css";

function SidebarWithOverview({overviewTopics}) {
  const { user } = useAuthenticator();

  return (
    <div className="overview_with_sidebar">
      <div className="overview_with_sidebar_top">
        {user ? (
          <div className="sidebar">
            <Sidebar />
          </div>
        ) : null}
        <Overview overviewTopics={overviewTopics} />
      </div>
    </div>
  );
}

export default SidebarWithOverview;
