import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import Toast from "./Toast";
import "./mentors.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs: 330, sm: 600, md:600, lg:600},
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function RequestForm({
  open,
  setOpen,
  mentorNameProp,
  mentorEmailProp,
  menteeNameProp,
  menteeEmailProp,
  emailBodyProp,
  canAddMentors,
}) {
  const [mentorError, setMentorError] = useState(false);
  const [menteeError, setMenteeError] = useState(false);
  const [emailSent, setEmailSent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailBody, setEmailBody] = useState("");

  const setValues = () => {
    setEmailBody(emailBodyProp);
    setMentorError(false);
    setMenteeError(false);
  };

  useEffect(() => {
    setValues();
  }, [emailBodyProp]);

  const handleClose = () => {
    setOpen(false);
    setValues();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URI}/email/`, {
        sender: menteeEmailProp,
        receiver: mentorEmailProp,
        message: emailBody,
        mentorName: mentorNameProp,
        menteeName: menteeNameProp,
      })
      .then((res) => {
        console.log("This is response " + res);
        setEmailSent(true);
        handleClose();
      })
      .catch((error) => {
        console.error("This is error " + error);
        setEmailSent(false);
      })
      .finally(() => {
        setLoading(false); // Set loading to false regardless of success or error
      });
    // }
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
          }}
        >
          {loading && (
            <div className="loading-overlay">
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                style={{color: "black"}}
              >
                Sending Email
              </LoadingButton>
            </div>
          )}
          {canAddMentors ? (
            <>
              <Typography variant="h6" component="h2">
                Become My Mentor
              </Typography>
              <Typography sx={{ mt: 2 }}>
                Let 2LeapMentoring help you to connect with your mentor. You can
                now send an email to your mentor to see if they are available to
                mentor you.
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  value={mentorEmailProp}
                  // onChange={(event) => setMentorEmail(event.target.value)}
                  sx={{ mt: "20px" }}
                  label={"Mentor Email"}
                  variant={"outlined"}
                  fullWidth
                  error={mentorError}
                  InputProps={{ readOnly: true }}
                  helperText={
                    mentorError ? "Please enter a valid NYU Email ID" : ""
                  }
                />
                <TextField
                  value={menteeEmailProp}
                  // onChange={(event) => setMenteeEmail(event.target.value)}
                  label={"Sender Email"}
                  sx={{ mt: "20px" }}
                  variant={"outlined"}
                  fullWidth
                  error={menteeError}
                  InputProps={{ readOnly: true }}
                  helperText={
                    menteeError ? "Please enter a valid NYU Email ID" : ""
                  }
                />
                <TextField
                  value={emailBody}
                  onChange={(event) => setEmailBody(event.target.value)}
                  label="Email Body"
                  sx={{ mt: "20px" }}
                  variant={"outlined"}
                  fullWidth
                  required
                  multiline
                  rows={3}
                />
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  paddingTop={"20px"}
                >
                  <Button onClick={handleClose}>Discard</Button>
                  <Button type="submit">Send Email</Button>
                </Box>
              </form>
            </>
          ) : (
            <>
              <Typography>
                Cannot send request
              </Typography>
            </>
          )}
        </Box>
      </Modal>
      <Toast emailSent={emailSent} setEmailSent={setEmailSent} />
    </div>
  );
}
