import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Appbar from "../Header/Appbar";
import "./resources.css";
import Sidebar from "../Sidebar/Sidebar";
import { Button } from "@mui/material";

function Resources() {
  const { user } = useAuthenticator();
  const url =
    "https://docs.google.com/spreadsheets/d/16LvoftW4ApR7KZgkOJ3xofn591iPOIw68b0PWpX6zd0/edit?usp=sharing";

  return (
    <div className="resources">
      <Appbar />
      <div className="resource__body">
        {user ? (
          <div className="sidebar">
            <Sidebar />
          </div>
        ) : null}
        <div className="links__body">
          <Button
            className="resources-btn"
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => window.open(url, "_blank")}
          >
            Early careeer award oppotunities
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Resources;
