import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
// import Toast from "./Toast";
import "./profile.css";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs: 300, sm: 500, md:600, lg:600},
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AddTaskForm({open, setOpen, heading, email}) {
    console.log(email);
  const [loading, setLoading] = useState(false);
  const [taskMessage, setTaskMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BACKEND_URI}/goals/`, {
        email: email,
        goal: taskMessage,
        checked: false,
        heading: heading
      })
      .then((res) => {
        console.log("This is response " + res.data);
        toast.success("Task Added successfully");
        handleClose();
        setOpen(false);
      })
      .catch((error) => {
        console.error("This is error " + error);
      })
    
  };
  const handleClose = () => {
    setOpen(false);
    setTaskMessage("");
  };
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
          }}
        >
          {loading && (
            <div className="loading-overlay">
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
              >
                Adding task under {heading}
              </LoadingButton>
            </div>
          )}

          <Typography variant="h6" component="h2">
            Add a task under {heading}
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              value={taskMessage}
              onChange={(event) => setTaskMessage(event.target.value)}
              label="Task Message"
              sx={{ mt: "20px" }}
              variant={"outlined"}
              fullWidth
              required
              multiline
              rows={1}
            />

            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"flex-end"}
              paddingTop={"20px"}
            >
              <Button type="submit">Add</Button>
            </Box>
          </form>
        </Box>
      </Modal>
      
    </div>
  );
}

export default AddTaskForm;
