import React, { useEffect } from "react";
import "./home.css";
import Logo from "../../assets/2leap symbol.png";
import Faculty_logo from "../../assets/Faculty_photo.jpg";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import "../../fonts/Gotham-Medium.otf";
import "../../fonts/Gotham-Book.otf";
import Appbar from "../Header/Appbar";
import Overview from "../Overview/Overview";

function Home() {
  const { user } = useAuthenticator();

  const overviewTopics = [
    "Home",
    "How it works",
    "Selecting a mentor",
    "Creating a 2leap Profile",
    "Training",
    "Feedback",
  ];

  useEffect(() => {
    const updateUser = async () => {
      console.log("inside update user");

      const user_new = await Auth.currentAuthenticatedUser();
      axios
        .post(`${process.env.REACT_APP_BACKEND_URI}/mentee/`, {
          name: user.attributes.name,
          email: user.attributes.email,
          mentors: [],
        })
        .then(async () => {
          console.log("Mentee registered");
          const customAttributes = {
            "custom:isRegistered": "1",
          };
          await Auth.updateUserAttributes(user_new, customAttributes);
        });
    };

    if (user) {
      console.log(user);
      if (!user.attributes.hasOwnProperty("custom:isRegistered")) {
        updateUser();
      }
    }
  }, [user]);

  return (
    <div className="home">
      {/* <Header showSearchBar={false} /> */}
      <Appbar />
      <div className="home__container">
        <div className="home_overview">
          <Overview overviewTopics={overviewTopics} />
        </div>
        <div className="home_right">
          <div className="article">
            <div className="article__header">
              <span className="empty_space" id="Home"></span>
              <h1 className="heading">2leap Mentoring</h1>
              <h5 className="subheading">
                The new mentoring program for NYU Tandon tenure-track faculty
              </h5>
              <p className="content">
                Through 2leap™ Mentoring, senior faculty members share
                experiences, expertise, and advice on research, teaching,
                work-life balance, and other professional concerns with NYU
                Tandon tenure-track faculty. Each year, tenure-track faculty are
                required to select two mentors by the beginning of February.
                Training for mentors and mentees will take place every year in
                January. Tenure-track faculty are required to change their
                mentors every year. However, they can go back to the same
                mentors every other year. At a minimum, mentees will have four
                mentor meetings per year (in total, not with each mentor).
                <br></br>
                <br></br>
                Read the &nbsp;
                <Link
                  className="NYU_links"
                  to="https://docs.google.com/document/d/1W04wMAlskTtBDtXF82ihFkhrxCAFJ9cR/edit#heading=h.xpr4muksvjec"
                  target="_blank"
                >
                  NYU Tandon 2leap™ Mentoring summary.
                </Link>
              </p>
            </div>
            <div className="image-column">
              <img className="img1" src={Faculty_logo} alt="Profile" />
              <img className="img2" src={Logo} alt="Profile" />
            </div>
          </div>
          <div className="article__content">
            <span className="empty_space" id="How it works"></span>
            <h1 className="title">How it works ?</h1>
            <ol className="pointer__content">
              <li>Create a 2 Leap mentoring profile.</li>
              <li>
                Review the &nbsp;
                <Link
                  className="NYU_links"
                  to="https://2leapmentoring.com/mentors"
                >
                  mentor profiles
                </Link>
                &nbsp; and their preferred mentoring topics.
              </li>
              <li>
                Pick any two mentors based on your interests (they do not have
                to be in your department).
              </li>
              <li>
                Initiate the contact by clicking on "Become My Mentor" and
                follow the instructions.
              </li>
              <li>
                Once a match has been confirmed to you by email, you can begin meeting with your
                mentor. Meet your mentors a minimum of four times a year.
              </li>
            </ol>

            <span className="empty_space" id="Selecting a mentor"></span>
            <h1 className="title">Selecting a Mentor</h1>
            <p className="content">
              Available mentors are listed in the main &nbsp;
              <Link
                className="NYU_links"
                to="https://2leapmentoring.com/mentors"
              >
                2leap™ Mentoring page.
              </Link>
            </p>
            <p className="content">
              After reviewing all the mentor profiles, tenure-track faculty can
              select two preferred mentors by clicking on the "become my mentor"
              button and and sending them the request to be their mentor. Upon
              sending the request, an email will be sent to their prospective
              mentor. The mentee will receive confirmation whether or not a
              match has been made. If a mentor does not accept the invitation,
              another selection should be made until TWO mentors are secured.
            </p>
            <p className="pointer__content">
              IMPORTANT:
              <ul>
                <li>
                  Mentees can select mentors outside their own department.
                </li>
                <li>
                  Department chairs can not be the mentor of someone in their
                  own department.
                </li>
              </ul>
            </p>

            <span className="empty_space" id="Creating a 2leap Profile"></span>
            <h1 className="title">Creating a 2Leap Profile</h1>
            <p className="content">
              Creating a 2Leap profile is your first step toward joining a
              vibrant community of learners and mentors. Whether you're here to
              share your experience or to learn from experienced professionals,
              your profile is the gateway to a world of knowledge exchange and
              personal growth.
            </p>
            <p className="pointer__content">
              Steps to set up the profile:
              <ul className="profile-list">
                <li>
                  <strong>Sign Up: </strong> Click on the &nbsp;
                  <Link to="/login" className="NYU_links">
                    Sign Up
                  </Link>
                  &nbsp; button and use your NYU account to begin your journey.
                </li>
                <li>
                  <strong> Set Your Goals: </strong> Specify your goals so we
                  can tailor your experience accordingly. You're in control of
                  how you want to engage.
                </li>
              </ul>
            </p>

            <span className="empty_space" id="Training"></span>
            <h1 className="title">Training</h1>
            <p className="content">
              Training is provided at the beginning of the year for both mentees
              and mentors so that they can make the mentorship a satisfying and
              useful experience.
              <br></br>
              <br></br>
              More information about the training events can be found here:
              &nbsp;
              <Link className="NYU_links" to="/resources">
                2leap Mentoring Program training events
              </Link>
            </p>
            <p className="para_subheading">2leap™ Mentoring for the mentees </p>
            <span className="pointer__content">
              <p>
                February, 2024 <br></br> Online
              </p>
            </span>

            <p className="para_subheading">2leap™ Mentoring for the mentors </p>
            <span className="pointer__content">
              <p>
                February, 2024 <br></br> Online
              </p>
            </span>

            <span className="empty_space" id="Feedback"></span>
            <h1 className="title">Feedback</h1>
            <p className="para_subheading"> Program Evaluation </p>
            <p className="pointer__content">
              Evaluations will take place twice a year:
              <ul>
                <li> June by questionnaire or focus groups </li>
                <li> January by survey and focus groups </li>
              </ul>
            </p>

            <p className="para_subheading"> Contact Information </p>
            <p className="content">
              Questions or concerns? Contact Marina Panina at &nbsp;
              <Link to="mailto:email@example.com" className="NYU_links">
                mp6057@nyu.edu
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
