import { Avatar } from "@mui/material";
import React from "react";
import "./sidebar.css";
import Profile from "../../assets/myphoto.jpeg";
import { useAuthenticator } from "@aws-amplify/ui-react";

function Sidebar() {
  const {user} = useAuthenticator();
  
  return (
    <div className="sidebar">
      <div className="sidebar__top">
        <img
          src="https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          alt="background"
        />
        <Avatar src={user.attributes.picture} className="sidebar__avator" />
        <h2>{user.attributes.name}</h2>
        <h4>{user.attributes.email}</h4>
      </div>
    </div>
  );
}

export default Sidebar;
