import React from "react";
import "./headerOption.css";
import { Avatar } from "@mui/material";

function HeaderOption({ profile, Icon, title }) {
  return (
    <div className="headerOption">
      {Icon && <Icon className="headerOption__icon" />}
      {profile && (
        <Avatar
          className="headerOption_icon"
          src={profile}
          style={{
            width: "30px",
            height: "30px",
            border: "2px solid #fffff",
          }}
        />
      )}
      <h3 className="headerOption__title">{title}</h3>
    </div>
  );
}

export default HeaderOption;
