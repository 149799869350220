import React, {useState} from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import axios from "axios";

function Comment({ comment, refresh, setRefresh }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useAuthenticator();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCommentDelete = async (id) => {
    // Implement your delete logic here
    console.log(id);
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URI}/comments/${id}`)
      .then((res) => console.log(res.data))
      .finally(() => {
        setRefresh(!refresh);
      });
    handleClose();
  };

  return (
    <div key={comment.id} className="comment">
      <div className="post-mentee-details" style={{ marginBottom: "0rem" }}>
        <AccountCircleIcon></AccountCircleIcon>
        <div className="name-conente">
          <Typography className="mentee-name">{comment.menteeName}</Typography>
          <Typography>{comment.content}</Typography>
        </div>

        <IconButton
          color="primary"
          style={{
            marginLeft: "auto",
            alignItems: "center",
            marginRight: "1rem",
          }}
          onClick={handleClick}
        >
          {user.attributes.email === comment.menteeId ? (
            <MoreHorizIcon style={{ color: "black" }} />
          ) : null}
        </IconButton>
        {/* <Typography>{comment.id}</Typography> */}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={() => handleCommentDelete(comment.id)}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default Comment;
